<template>
  <div class="relative-form sub-form">
    <div class="heading">
      <div class="text-md text-lg-mobile bold">{{ label }}</div>
      <close-icon class="close-icon" @click="$emit('remove')"></close-icon>
    </div>

    <hr />

    <div class="inline mobile-column">
      <div class="input-wrapper part">
        <div class="input-label">First Name</div>
        <input
          class="input full-width"
          :value="relative.first_name"
          @input="onFirstNameChange"
          @keyup.enter="onInputEnter"
        />
      </div>
      <div class="input-wrapper part">
        <div class="input-label">Surname</div>
        <input
          class="input full-width"
          :value="relative.surname"
          @input="onSurnameChange"
          @keyup.enter="onInputEnter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/CloseCircle';

export default {
  props: {
    label: String,
    relative: Object,
  },
  methods: {
    onFirstNameChange(event) {
      this.$emit('change-first-name', event.target.value.trim());
    },
    onSurnameChange(event) {
      this.$emit('change-surname', event.target.value.trim());
    },
    onInputEnter() {
      this.$emit('submit');
    },
  },
  components: {CloseIcon},
  name: 'RelativeForm',
};
</script>

<style lang="scss" scoped>
.relative-form {
}
</style>
