import {VALIDATE_FACT_FIELD_SET} from '@common/utils/consts.search';

export function isSourcesFormPlaceFieldDisabled(formData) {
  const ancestralPlaceId = formData.ancestral_place && formData.ancestral_place.id;
  return Boolean(formData.country || ancestralPlaceId);
}

export function getFactsForSearch(formData) {
  return formData.facts.filter(fact => {
    return VALIDATE_FACT_FIELD_SET.some(fieldName => fact[fieldName]);
  });
}

export function getClanName(clan) {
  const nameCh =
    clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
  return [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
}

export function isRecordYearValid(year) {
  if (!year || typeof year === 'number') {
    return true;
  }
  if (typeof year === 'string') {
    const onlyDigits = /^\d+$/;
    const cFormat = /^c-\d+$/;
    const dFormat = /^d-\d+$/;
    return onlyDigits.test(year) || cFormat.test(year) || dFormat.test(year);
  }
  return false;
}
