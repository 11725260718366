<template>
  <div class="tabs-block">
    <div class="tabs-header" :class="tabsHeaderClasses">
      <div
        v-for="item in headerTabItems"
        :key="item.identifier"
        @click="activateTab(item.identifier)"
        class="tab"
        :class="{'is-active': item.identifier === tabActiveId}"
      >
        {{ getItemName(item) }}
      </div>
    </div>

    <div class="tabs-content stand-out">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';

export default {
  props: {
    startTab: String,
  },
  data() {
    return {
      tabItems: {},
      tabActiveId: '',
      tabsContentRef: 'tabs-content',
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.tablet;
    },
    tabsHeaderClasses() {
      return this.isMobile ? 'is-switch' : '';
    },
    headerTabItems() {
      const values = Object.values(this.tabItems).filter(item => item.visible);
      if (values.length && values[0].priority) {
        return sortBy(values, 'priority');
      }
      return values;
    },
  },
  methods: {
    register(tabItemInstance) {
      if (this.isTabItemComponent(tabItemInstance.$options)) {
        this.tabItems = {
          ...this.tabItems,
          [tabItemInstance.identifier]: this.tabItems[tabItemInstance.identifier] || tabItemInstance,
        };
        if (!this.tabActiveId && (!this.startTab || this.startTab === tabItemInstance.identifier)) {
          this.activateTab(tabItemInstance.identifier);
        }
      }
    },
    isTabItemComponent(options) {
      return options && options.name === 'TabsBlockItem';
    },
    activateTab(identifier) {
      if (this.tabActiveId === identifier) {
        return;
      }
      const fromTabId = this.tabActiveId;
      this.tabActiveId = identifier;
      for (let tab of Object.values(this.tabItems)) {
        tab.setIsActive(tab.identifier === this.tabActiveId);
      }
      this.$emit('activate-tab', {tabId: this.tabActiveId, fromTabId});
    },
    getItemName(item) {
      return this.isMobile && item.nameMobile ? item.nameMobile : item.name;
    },
  },
  name: 'TabsBlock',
};
</script>

<style lang="scss" scoped>
.stand-out {
  background-color: $background-light;
  border-radius: 4px;
  padding: 24px 20px 32px;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 16px;
  }
}

.tabs-block {
  .tabs-header {
    display: flex;

    .tab {
      padding: 10px;
      font-weight: 400;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      color: $neutral-500;
      z-index: 1;
      cursor: pointer;
      margin-right: 15px;

      &.is-active {
        background-color: $background-light;
        font-weight: 800;
        z-index: 3;
        color: $neutral-600;
        border-bottom: 2px solid $primary-400;
      }
    }

    &.is-switch {
      border-radius: 4px;
      margin-bottom: 20px;
      $tab-color: $mcr-dark-grey;

      .tab {
        border: 1px solid $tab-color;
        color: $tab-color;
        margin-right: 0;
        border-radius: 0;
        padding: 8px 12px;
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;

        &:first-of-type {
          border-radius: 4px 0 0 4px;
        }
        &:not(:first-of-type) {
          border-left: none;
        }
        &:last-of-type {
          border-radius: 0 4px 4px 0;
        }
        &.is-active {
          background-color: $tab-color;
          color: $off-white;
        }
      }
    }
  }
}
</style>
