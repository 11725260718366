<template>
  <div class="category-details" v-if="showDetails">
    <div v-if="wikiArticleRoute" class="wiki-article">
      <h5>About {{ searchCategoryState.explicit_name }}</h5>
      <div v-html="searchCategoryState.wiki_article.content" v-if="searchCategoryState.wiki_article"></div>
      <router-link :to="wikiArticleRoute" class="read-more-link bold">Read more on the Genealogy Wiki</router-link>
    </div>
    <hr v-if="isZupusCategory" />
    <div v-if="isZupusCategory" class="zupu-faq">
      <div class="bold">Frequently Asked Questions</div>
      <ul class="desc">
        <li><mcr-wiki-glossary-link naturalId="whySearchZupu" text="Why should I search for my zupu?" /></li>
        <li><mcr-wiki-glossary-link naturalId="how-to-search-zupu" text="How do I find my zupu?" /></li>
        <li><mcr-wiki-glossary-link naturalId="knowZupu" text="I found my zupu. Now what?" /></li>
      </ul>
    </div>
    <hr />
    <div class="info-item">
      <strong>Record Types: </strong
      ><span v-for="sourceType in searchCategoryState.source_types" :key="sourceType.id" class="name">{{
        sourceType.label
      }}</span>
    </div>
    <div class="info-item">
      <strong>Source Countries: </strong
      ><router-link
        v-for="country in searchCategoryState.source_countries"
        :key="country.id"
        :to="getCountryRoute(country)"
        class="name"
        >{{ country.pinyin }}</router-link
      >
    </div>
    <div class="info-item">
      <strong>Language: </strong><span class="name">{{ searchCategoryState.languages }}</span>
    </div>
    <div class="info-item" v-if="searchCategoryState.persons_count">
      <strong>Persons Indexed: </strong
      ><span class="name">{{ searchCategoryState.persons_count.toLocaleString('en') }}</span>
    </div>
    <div class="info-item" v-if="searchCategoryState.books_count">
      <strong>Books in Database: </strong
      ><span class="name">{{ searchCategoryState.books_count.toLocaleString('en') }}</span>
    </div>
    <div v-if="searchCategoryState.wiki_article && searchCategoryState.wiki_article.image" class="wiki-article-image">
      <image-caption
        :img-source="searchCategoryState.wiki_article.image"
        caption="View Sample"
        width="800"
        height="400"
        @click-image="showImageGallery"
      ></image-caption>
      <images-gallery-overlay-container></images-gallery-overlay-container>
    </div>
    <hr />
    <div class="link-wrapper">
      <router-link :to="sourcesSearchRoute" class="bold"
        >View all sources in the {{ searchCategoryState.name }} category
      </router-link>
    </div>
    <hr />
  </div>
  <div class="all-records-details" v-else-if="showAllRecordsDetails && !searchCategoriesLoadingState">
    <div class="heading-5 heading-4-mobile">Record Categories</div>
    <div class="categories">
      <record-category-block :item="item" v-for="item in items" :key="item.id"></record-category-block>
    </div>
  </div>
  <div v-else>
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
</template>

<script>
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import ImageCaption from '@common/elements/image-caption';
import RecordCategoryBlock from '@common/elements/layouts/RecordCategoryBlock';
import {TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {getGalleryImageData} from '@common/utils/utils';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import orderBy from 'lodash/orderBy';
import {mapGetters} from 'vuex';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  created() {
    this.init();
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        if (toQuery.category_id !== fromQuery.category_id) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['searchCategoryState', 'searchCategoriesListState', 'searchCategoriesLoadingState']),
    showDetails() {
      return this.$route.query.category_id && this.$route.query.category_id === this.searchCategoryState.id;
    },
    showAllRecordsDetails() {
      return !this.$route.query.category_id;
    },
    sourcesSearchRoute() {
      const query = {results: 'true', tab: TAB_ID_SOURCES, category_id: this.searchCategoryState.id};
      return {name: 'search-all-records', query};
    },
    wikiArticleRoute() {
      if (this.searchCategoryState.wiki_article) {
        return {name: 'wiki-article', params: {slug: this.searchCategoryState.wiki_article.slug}};
      }
    },
    isZupusCategory() {
      return this.$route.query.category_id === sourceTypeCategories.CATEGORY_ZUPUS;
    },
    items() {
      return orderBy(this.searchCategoriesListState, ['is_highlighted', 'priority'], ['desc', 'asc']);
    },
  },
  methods: {
    init() {
      if (this.showAllRecordsDetails && !this.searchCategoriesListState.length) {
        this.$store.dispatch('fetchSearchCategoriesListAction');
      }
    },
    getCountryRoute(country) {
      return getPlaceDetailRoute(country.id, country.pinyin);
    },
    showImageGallery() {
      const data = [getGalleryImageData(0, this.searchCategoryState.wiki_article.image, '', {}, true)];
      this.$store.commit('setGalleryItemsState', data);
      this.$store.dispatch('displayGalleryAction', 0);
    },
  },
  components: {RecordCategoryBlock, mcrWikiGlossaryLink, ImagesGalleryOverlayContainer, ImageCaption},
  name: 'CategoryDetails',
};
</script>

<style lang="scss" scoped>
.category-details {
  .link-wrapper {
    margin-top: 16px;
  }

  .wiki-article {
    margin-top: 40px;
  }

  .wiki-article-image {
    margin-top: 16px;
    .image-caption::v-deep img {
      cursor: pointer;
    }
  }

  hr {
    margin: 24px 0;
  }

  .info-item {
    margin-top: 16px;
  }

  .info-item .name:not(:last-child)::after {
    content: ', ';
  }

  .read-more-link {
    margin-top: 16px;
    display: block;
  }

  .wiki-article + .info-item {
    margin-top: 36px;
  }

  .info-item + .wiki-article-image {
    margin-top: 36px;
  }
}

.all-records-details {
  margin-top: 40px;

  .categories {
    margin-top: 12px;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .categories {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
