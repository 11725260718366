<template>
  <stand-out-block class="search-books-item result-item" :class="{'is-row': isDesktop}">
    <div class="content">
      <h4 class="title">
        <router-link :to="sourceDetails" class="details-link">
          <div class="heading">{{ item.book_meta.book_name }}</div>
        </router-link>
      </h4>
      <div class="book-meta">
        <record-type :type-name="recordTypeLabel"></record-type>
      </div>

      <div class="mobile-image" v-if="!isDesktop">
        <img :data-src="imageUrl" class="image with-shadow lazyload" @click="onImageClick" />
      </div>

      <div class="meta-info">
        <div v-if="clanData">
          <span class="supplemental">Clan: </span><span>{{ clanData }}</span>
        </div>
        <div v-if="publishData">
          <span class="supplemental">Published: </span><span>{{ publishData }}</span>
        </div>
      </div>

      <div class="snippets">
        <div v-for="(highlight, index) in trimmedSnippets">
          "...<span v-html="highlight" class="snippet"></span>..."
          <div v-if="index + 1 !== trimmedSnippets.length" class="highlight-separator supplemental">...</div>
        </div>
      </div>

      <div class="view-button-container">
        <router-link :to="imageViewer" class="view-button"
          ><search-icon :size="20" class="view-icon"></search-icon> Examine Matches
        </router-link>
        <router-link :to="sourceDetails" class="view-button"
          ><view-icon :size="20" class="view-icon"></view-icon> View Source Details</router-link
        >
      </div>
    </div>

    <div class="desktop-image" v-if="isDesktop">
      <img :data-src="imageUrl" class="image lazyload" :alt="item.book_name" @click="onImageClick" />
    </div>
  </stand-out-block>
</template>

<script>
import RecordType from '@common/elements/icons/RecordType';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {sourceImageFastThumbnailLink} from '@common/utils/utils';
import ViewIcon from 'vue-material-design-icons/FolderMoveOutline';
import SearchIcon from 'vue-material-design-icons/FolderSearchOutline';

export default {
  props: {
    item: Object,
    meta: Object,
  },
  computed: {
    imageViewer() {
      const firstName = this.$route.query.q;
      const lastName = this.$route.query.last_name;
      const query = this.item.highlight.length ? {page_id: this.item.image_id} : {page: 1};
      if (this.item.type === 'zupu' && this.item.book_meta.info.clan_pedigree_id) {
        query.search = firstName;
        return {name: 'source-viewer', params: {sourceId: this.item.book_id}, query};
      }
      query.search = `${firstName || ''} ${lastName || ''}`.trim();
      return {name: 'source-viewer', params: {sourceId: this.item.book_id}, query};
    },
    sourceDetails() {
      if (this.item.type === 'zupu' && this.item.book_meta.info.clan_pedigree_id) {
        return {name: 'search-tool-zupu-detail', params: {zupuId: this.item.book_meta.info.clan_pedigree_id}};
      }
      return {name: 'source-detail', params: {sourceId: this.item.book_id}};
    },
    publishData() {
      const place_name = this.item.book_meta.info.place_name;
      const publication_year = this.item.book_meta.info.year;

      if (place_name && publication_year) {
        return `${place_name}, ${publication_year}`;
      }
      return place_name || publication_year;
    },
    clanData() {
      const clans = this.item.clans
        .map(clanId => {
          const clanObj =
            clanId && clanId !== 'none' ? this.meta.facets.clans.find(clan => clan.object_id === clanId) : null;
          return clanObj ? `${clanObj.pinyin} ${clanObj.name_hant || clanObj.name_ch}` : null;
        })
        .filter(clan => !!clan);
      const familyWord = clans.length < 2 ? ' family' : ' families';
      const clansStr = clans.length ? clans.join(', ') + familyWord : '';
      const ancestralPlaces = this.item.book_meta.info.ancestral_place_names || [];
      const ancestorPlace = ancestralPlaces.join(', ');
      if (clansStr && ancestorPlace) {
        return `${clansStr} from ${ancestorPlace}`;
      }
      return clansStr;
    },
    recordTypeLabel() {
      const type = this.item.type_display && this.item.type_display !== 'none' ? this.item.type_display : '';
      const country = this.item.country && this.item.country !== 'none' ? this.item.country : '';
      return type && country ? `${country}, ${type}` : type || country;
    },
    isDesktop() {
      const width = this.$store.getters.windowWidthState;
      return width >= 1100 || (width >= this.$breakpoints.phablet && width <= this.$breakpoints.tablet);
    },
    trimmedSnippets() {
      const CHARS_COUNT = 55;
      return this.item.highlight.map(text => {
        if (text.length <= CHARS_COUNT) {
          return text;
        }
        let firstIndex = text.indexOf('<em>');
        let lastIndex = text.lastIndexOf('</em>') + 5;
        if (lastIndex - firstIndex < CHARS_COUNT) {
          const needChars = Math.round((CHARS_COUNT - (lastIndex - firstIndex)) / 2);
          firstIndex = firstIndex - needChars >= 0 ? firstIndex - needChars : 0;
          lastIndex = lastIndex + needChars;
        }
        return text.substring(firstIndex, lastIndex);
      });
    },
    imageUrl() {
      return sourceImageFastThumbnailLink(this.item.thumbnail_token, 400);
    },
  },
  methods: {
    onImageClick() {
      this.$router.push(this.imageViewer);
    },
  },
  components: {StandOutBlock, RecordType, ViewIcon, SearchIcon},
};
</script>

<style lang="scss" scoped>
@import 'styles/result-item';

.search-books-item {
  .content {
    flex-grow: 1;

    .highlight-separator {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .snippets::v-deep {
    margin: 20px 0;
    padding: 15px;
    background-color: $mcr-grey-tan;
    border-radius: 4px;

    em {
      background-color: $highlight-color;
    }
  }
}
</style>
