<template>
  <page-record-offline v-if="isRecordOffline"></page-record-offline>
  <page-not-found v-else-if="isRecordNotExist"></page-not-found>
  <div class="person-records-page" v-else>
    <div class="limited_content" v-if="loading">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="limited_content" v-else>
      <h5 v-if="!personMentionsState.source_mentions.length">Records Not Found</h5>
      <router-link :to="mentionPrevRouteState" v-if="mentionPrevRouteState" class="back-button"
        >{{ backButtonLabel }}
      </router-link>
      <record-component
        v-for="source in personMentionsState.source_mentions"
        :key="source.id"
        :is-classified="!personMentionsState.user_has_full_access"
        :images="source.images"
        :images-count="source.images_count"
        :source="source"
        :record-type-display="source.mention_type_display"
        :full-name-en="fullNameEn"
        :full-name-ch="fullNameCh"
        :birth-date="personMentionsState.birth_date"
        :death-date="personMentionsState.death_date"
        :images-selectable="userIsStaffState"
        :description="source.description"
        :person-notes="personMentionsState.notes"
        :is-data-protected="isDataProtected"
        class="section"
        @click-image="onClickImage"
        @delete-images="onDeleteImages"
      >
        <template slot="header-buttons">
          <mcr-button
            v-if="saveRecordAllowed && !isDataProtected"
            class="action-button"
            @click="onClickSaveRecord(source)"
            >Save Record
          </mcr-button>
        </template>

        <div slot="admin">
          <admin-controls
            :person-id="personMentionsState.object_id"
            :external-person-id="personMentionsState.external_person_id"
            :is-potentially-living="personMentionsState.is_potentially_living"
            :potential-death-date="personMentionsState.potential_death_date"
            :source="source"
          ></admin-controls>

          <template v-if="personMentionsState && userIsStaffState">
            <admin-person-edit-modal
              @update="init"
              :modifier="source.id"
              :person="personMentionsState"
              :meta="getStaffMeta(source)"
            ></admin-person-edit-modal>
            <add-relative-modal
              :person-id="personMentionsState.object_id"
              :source-id="source.id"
              :image-ids="getSourceImageIds(source)"
              @update="init"
            ></add-relative-modal>
          </template>
        </div>
        <template v-slot:subscription-link>
          <subscription-plans-link
            :label="subscribeLabel"
            :record-title="subscribeTitle"
            class="fat"
          ></subscription-plans-link>
        </template>
        <div class="section" v-if="showRelativesTable">
          <div class="wrapper">
            <relatives-table
              :relatives="personMentionsState.related_persons"
              :relatives-json="personMentionsState.relatives_json"
              :is-classified="!personMentionsState.user_has_full_access"
            >
            </relatives-table>
          </div>
        </div>
        <record-metadata-table
          class="section"
          :first-names-en="firstNamesEn"
          :surnames-en="surnamesEn"
          :first-names-ch="firstNamesCh"
          :surnames-ch="surnamesCh"
          :other-names="otherNames"
          :mention="personMentionsState"
          :source="source"
          :other-mentions="filteredOtherMentionsByMentionId[source.id]"
          :is-data-protected="isDataProtected"
        ></record-metadata-table>
        <p class="supplemental providers" v-if="source.providers && source.providers.length">
          Record made available courtesy of the
          <template v-for="provider in source.providers">
            <a :href="provider.url" target="_blank" rel="nofollow" v-if="provider.url" class="name">
              {{ provider.name }}
            </a>
            <span v-else class="name">{{ provider.name }}</span>
          </template>
        </p>

        <template v-if="!isDataProtected">
          <div class="section" v-for="unit in personMentionsState.units">
            <unit-table
              :unit="unit"
              :key="unit.id"
              :record-type="personMentionsState.source_mentions[0].mention_type_display"
              :is-classified="!personMentionsState.user_has_full_access"
            ></unit-table>
          </div>
        </template>

        <div class="section section-more-records" v-if="potentialMentionsCount > 1">
          <section-more-records
            :first-name-en="firstNameEn"
            :first-name-ch="firstNameCh"
            :surname-en="surnameEn"
            :surname-ch="surnameCh"
            :mentions-count="potentialMentionsCount"
          ></section-more-records>
        </div>

        <div class="section section-more-records">
          <mcr-loading-indicator v-if="zupusIsLoading" :loading="true"></mcr-loading-indicator>
          <section-zupus
            v-else-if="firstSurname && (sourcesTextSearchCountState || clanPedigreesSearchMetaState.total_count)"
            :full-name-en="fullNameEn"
            :full-name-cn="fullNameCh"
            :zupu-count="clanPedigreesSearchMetaState.total_count"
            :text-matches-count="sourcesTextSearchCountState"
            :search-surname="firstSurname"
            :search-firstname="firstNameCh"
          ></section-zupus>
        </div>
      </record-component>

      <images-delete-confirm-modal
        v-if="userIsStaffState"
        :loading="deleteImagesLoadingState"
        :confirmMethod="deleteImages"
      ></images-delete-confirm-modal>

      <prospect-request-end-action class="section" title="Need help with this record?"></prospect-request-end-action>
    </div>
    <mentions-images-gallery-overlay-container
      :getNextPage="mentionsImagesGetNextPage"
    ></mentions-images-gallery-overlay-container>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import RecordMetadataTable from '@common/elements/layouts/RecordMetadataTable.vue';
import PageRecordOffline from '@common/pages/pageRecordOffline';
import MentionsImagesGalleryOverlayContainer from '@common/pages/personRecords/MentionsImagesGalleryOverlayContainer';
import RecordComponent from '@common/pages/personRecords/RecordComponent';
import RelativesTable from '@common/pages/personRecords/RelativesTable.vue';
import SectionMoreRecords from '@common/pages/personRecords/SectionMoreRecords';
import SectionZupus from '@common/pages/personRecords/SectionZupus';
import UnitTable from '@common/pages/personRecords/UnitTable';
import personRecordsPage from '@common/pages/personRecords/js/person-records-page';
import {isEmpty} from 'lodash';
import {mapGetters} from 'vuex';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink';
import SaveMentionModalContent from '@/components/common/modals/SaveMentionModalContent';
import ProspectRequestEndAction from '@/components/common/prospectRequestEndAction';

import PageNotFound from '@/components/page.not.found';

const AdminControls = () => import('./admin/AdminControls');
const AddRelativeModal = () => import('@/components/modules/personRecords/admin/AddRelativeModal');
const ImagesDeleteConfirmModal = () => import('@/components/modules/personRecords/admin/ImagesDeleteConfirmModal');
const AdminPersonEditModal = () => import('@/components/common/forms/admin/AdminPersonEditModal');

export default {
  metaInfo() {
    return personRecordsPage.getMetaInfo(this);
  },
  data() {
    return {
      personCode: this.$route.params.code,
      imagesIdsToDelete: [],
      potentialMentionsCount: 0,
      isRecordOffline: false,
      isRecordNotExist: false,
      subscribeLabel: 'Subscribe to View',
    };
  },
  created() {
    if (this.personMentionsState && this.personMentionsState.code === this.personCode) {
      this.getPotentialMentionsCount();
      return;
    }
    this.init();
  },
  beforeRouteEnter(to, fromR, next) {
    next(vm => {
      personRecordsPage.savePrevRoute(fromR, vm);
    });
  },
  watch: {
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (toParams.code !== fromParams.code) {
          this.init();
          this.$scrollTo({x: 0});
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'personMentionsLoadingState',
      'personMentionsState',
      'userIsStaffState',
      'deleteImagesLoadingState',
      'sourcesTextSearchCountState',
      'sourcesTextSearchCountLoadingState',
      'clanPedigreesSearchMetaState',
      'clanPedigreesSearchLoadingState',
      'mentionPrevRouteState',
      'userIsLoggedInState',
      'userIsSharedState',
      'featureSwitchesState',
    ]),
    loading() {
      return isEmpty(this.personMentionsState) || this.personMentionsLoadingState;
    },
    firstNamesEn() {
      return personRecordsPage.getFirstNamesEn(this);
    },
    firstNamesCh() {
      return personRecordsPage.getFirstNamesCh(this);
    },
    surnamesEn() {
      return personRecordsPage.getSurnamesEn(this);
    },
    surnamesCh() {
      return personRecordsPage.getSurnamesCh(this);
    },
    otherNames() {
      return personRecordsPage.getOtherNames(this);
    },
    firstSurname() {
      if (this.surnameCh) {
        return this.surnameCh;
      }
      return this.personMentionsState.surnames.length ? this.personMentionsState.surnames[0].value : null;
    },
    zupusIsLoading() {
      return this.sourcesTextSearchCountLoadingState || this.clanPedigreesSearchLoadingState;
    },
    fullNameEn() {
      return personRecordsPage.getFullNameEn(this);
    },
    firstNameEn() {
      return this.firstNamesEn.length ? this.firstNamesEn[0].value : '';
    },
    surnameEn() {
      return this.surnamesEn.length ? this.surnamesEn[0].value : '';
    },
    firstNameCh() {
      return this.firstNamesCh.length ? this.firstNamesCh[0].value : '';
    },
    surnameCh() {
      return this.surnamesCh.length ? this.surnamesCh[0].value : '';
    },
    fullNameCh() {
      return `${this.surnameCh}${this.firstNameCh}`;
    },
    backButtonLabel() {
      return personRecordsPage.getBackButtonLabel(this.mentionPrevRouteState);
    },
    saveRecordAllowed() {
      return this.userIsLoggedInState && !this.userIsSharedState && this.personMentionsState.user_has_full_access;
    },
    filteredOtherMentionsByMentionId() {
      return personRecordsPage.getFilteredOtherMentions(
        this.personMentionsState.units,
        this.personMentionsState.source_mentions
      );
    },
    showRelativesTable() {
      return personRecordsPage.showRelativesTable(this);
    },
    isDataProtected() {
      return personRecordsPage.isDataProtected(this);
    },
    subscribeTitle() {
      return this.fullNameEn || this.fullNameCh || '';
    },
  },
  methods: {
    onClickImage({image, sourceId, zupuId}) {
      personRecordsPage.onClickImage(image, sourceId, zupuId, this);
    },
    mentionsImagesGetNextPage() {
      personRecordsPage.mentionsImagesGetNextPage(this);
    },
    getViewerRoute(priority, id, sourceId, zupuId) {
      personRecordsPage.getViewerRoute(priority, id, sourceId, zupuId);
    },
    init() {
      personRecordsPage.init(this);
    },
    getStaffMeta(source) {
      return {
        birth_place: source.staff_info.length ? source.staff_info[0].birth_place || '' : '',
        name: source.staff_info.length ? source.staff_info[0].name || '' : '',
        source_urls: source.source_url,
        transcription: source.transcription.join('\n'),
        internal_notes: source.internal_notes.join('\n'),
      };
    },
    getSourceImageIds(source) {
      return source.images.map(i => i.id);
    },
    onDeleteImages(ids) {
      this.imagesIdsToDelete = ids;
      this.$modal.show('images-delete-confirm');
    },
    deleteImages() {
      this.$store.dispatch('bulkRemoveSourceImagesAction', {imageIds: this.imagesIdsToDelete}).then(() => {
        window.location.reload();
      });
    },
    getPotentialMentionsCount() {
      return personRecordsPage.getPotentialMentionsCount(this);
    },
    onClickSaveRecord(source) {
      this.$modal.show(
        SaveMentionModalContent,
        {recordMentionId: source.mention_id, source: source, matchPersonId: this.personMentionsState.object_id},
        {classes: 'clear_modal white_modal', scrollable: true, height: 'auto'}
      );
    },
  },
  name: 'PersonRecordsPage',
  components: {
    SubscriptionPlansLink,
    RecordMetadataTable,
    RelativesTable,
    MentionsImagesGalleryOverlayContainer,
    SectionMoreRecords,
    ProspectRequestEndAction,
    ImagesDeleteConfirmModal,
    AddRelativeModal,
    AdminControls,
    AdminPersonEditModal,
    RecordComponent,
    SectionZupus,
    PageRecordOffline,
    PageNotFound,
    McrButton,
    UnitTable,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/personRecords/styles/person-records-page.scss';
</style>
