<template>
  <a :href="url" target="_blank">
    <card-with-ribbon :class="classes" text="Go to Partner Site" class="external-source-logo">
      <link-icon class="link-icon" slot="icon" :size="30"></link-icon>
      <img :src="imgSrc || providerLogo" />
      <div v-if="showText" slot="default" class="attachments-text">{{ externalImagesText }}</div>
    </card-with-ribbon>
  </a>
</template>

<script>
import CardWithRibbon from '@common/elements/layouts/CardWithRibbon';
import LinkIcon from 'vue-material-design-icons/OpenInNew';

export default {
  props: {
    hasAttachments: Boolean,
    url: String,
    imgSrc: {type: String, required: false},
    showText: {type: Boolean, default: false},
  },
  computed: {
    providerLogo() {
      // ToDo: refactor to get from provider
      if (this.url && this.url.includes('wellsfargohistory.com')) {
        return this.$getAsset('/assets/search/wells-fargo-logo.png');
      }
      return this.$getAsset('/assets/services/zupus/NARA-logo-cropped.png');
    },
    externalImagesText() {
      return this.hasAttachments ? 'Photos available' : 'Photos may be available';
    },
    classes() {
      return {'is-provider-logo': !Boolean(this.imgSrc)};
    },
  },
  name: 'ExternalSourceLogo',
  components: {CardWithRibbon, LinkIcon},
};
</script>

<style lang="scss" scoped>
.card-with-ribbon.external-source-logo {
  background-color: $background-label;
  position: relative;

  .link-icon {
    transform: rotate(-45deg);
    margin-bottom: 15px;
  }

  .attachments-text {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    color: $supplemental-text-color;
  }

  &::v-deep .corner-ribbon {
    width: 280px;
    height: 140px;
  }

  &.is-provider-logo {
    padding: 70px 0;
    max-width: unset;
    min-height: unset;

    img {
      opacity: 0.3;
      min-height: 200px;
      height: 200px;
      margin: auto;
    }
  }
}
</style>
