import { render, staticRenderFns } from "./SurnameInput.vue?vue&type=template&id=10d6ee60&scoped=true&"
import script from "./SurnameInput.vue?vue&type=script&lang=js&"
export * from "./SurnameInput.vue?vue&type=script&lang=js&"
import style0 from "./SurnameInput.vue?vue&type=style&index=0&id=10d6ee60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d6ee60",
  null
  
)

export default component.exports