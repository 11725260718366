<template>
  <div class="search-error">
    <h4 class="error">{{ errorText }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    text: {type: String},
  },
  computed: {
    errorText() {
      return this.text || 'An error occurred while searching. Please try again later.';
    },
  },
  name: 'SearchError',
};
</script>

<style lang="scss" scoped>
.search-error {
  margin-bottom: 40px;
}
</style>
