<template>
  <div class="image-item" :class="classes" @click="onClick">
    <slot></slot>
    <img :src="previewUrl" class="with-shadow" />
    <div class="overlay" v-if="overlayText">
      <span>{{ overlayText }}</span>
    </div>
    <check-circle-icon v-if="selectable" class="check-icon" :size="50" @click.stop="onSelect"></check-circle-icon>
  </div>
</template>

<script>
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle';

export default {
  props: {
    src: String,
    overlayText: String,
    isSelected: Boolean,
    selectable: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    onSelect() {
      this.$emit('select');
    },
  },
  computed: {
    classes() {
      return {'is-selected': this.isSelected};
    },
    previewUrl() {
      return this.src;
    },
  },
  components: {CheckCircleIcon},
};
</script>

<style lang="scss" scoped>
.image-item {
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .check-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    opacity: 0.5;
    display: none;
    &:hover {
      opacity: 1;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $background-color;
    opacity: 0.8;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &:hover {
    .check-icon {
      display: flex;
    }
  }

  &.is-selected {
    img {
      border: 5px solid $power-red;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .check-icon {
      color: $mcr-red;
      display: flex;
      background-color: white;
      border-radius: 50px;
      opacity: 1;
    }
  }
}
</style>
