<template>
  <div class="search-records-results">
    <base-pagination-meta class="main-meta" :meta="meta" :title="title"></base-pagination-meta>
    <div class="search-results">
      <full-text-item :item="firstItem" :meta="meta"></full-text-item>
      <slot name="top-cta"></slot>
      <full-text-item v-for="item in otherList" :item="item" :meta="meta" :key="item.object_id"></full-text-item>
    </div>
    <base-pagination-meta class="bottom-meta" :meta="meta"></base-pagination-meta>
    <base-pagination :meta="meta" @onSwitchPage="onSwitchPage"></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import BasePaginationMeta from '@common/elements/base-pagination-meta';
import FullTextItem from '@common/pages/searches/FullTextItem';

export default {
  props: {
    filters: Object,
    meta: Object,
    list: Array,
    title: String,
  },
  data() {
    const [firstItem, ...otherList] = this.list;
    return {
      firstItem,
      otherList,
    };
  },
  methods: {
    onSwitchPage(page) {
      this.$emit('switch-page', page);
    },
  },
  components: {BasePaginationMeta, BasePagination, FullTextItem},
  name: 'FullTextResults',
};
</script>

<style scoped></style>
