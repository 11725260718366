<template>
  <div class="surname-input">
    <multiselect
      :value="value"
      ref="surname-input"
      id="clan-surname"
      :placeholder="defaultPlaceholder"
      :searchable="true"
      :internal-search="false"
      :preserveSearch="false"
      :clearOnSelect="true"
      :show-no-options="true"
      :show-no-results="searchRan"
      :options="clanOptions"
      :hide-selected="false"
      track-by="name_ch"
      label="name_ch"
      openDirection="bottom"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      :loading="clansSearchLoadingState"
      :class="classes"
      @open="onOpen"
      @search-change="onSearchChange"
      @select="onClanSelect"
      @close="onClose"
    >
      <template v-slot:caret>
        <div
          class="caret-icon close-icon"
          v-if="value.name_ch && !isMultiselectOpened()"
          @mousedown.prevent.stop="clearClan()"
        >
          <close-icon></close-icon>
        </div>
      </template>
      <span slot="noResult" class="input-helper">No elements found.</span>
      <span slot="noOptions" class="input-helper">Start typing to search...</span>
      <template v-slot:option="params">
        <span class="chinese-name">{{ getChineseNameDisplay(params.option) }}</span>
        <span class="english-name">{{ getEnglishNameDisplay(params.option) }}</span>
      </template>
      <span slot="singleLabel" slot-scope="params">
        <template v-if="params.option && params.option.name_ch">
          <span class="chinese-name">{{ getChineseNameDisplay(params.option) }}</span>
          <span class="english-name">{{ getEnglishNameDisplay(params.option) }}</span>
        </template>
        <span v-else>{{ defaultPlaceholder }}</span>
      </span>
      <div slot="afterList" class="loading-after-list" v-if="clansSearchLoadingState">
        <span class="input-helper">Searching...</span>
      </div>
    </multiselect>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import {isChineseText} from '@common/utils/utils';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  props: {
    value: {type: Object},
    error: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    showDefaultLabel: {type: Boolean, default: false},
    allowSpellingSelect: {type: Boolean, default: false},
  },
  data() {
    return {
      searchRan: false,
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters(['clansSearchState', 'clansSearchLoadingState']),
    classes() {
      return {'has-error': !!this.error, bordered: true};
    },
    clanOptions() {
      if (this.allowSpellingSelect && this.clansSearchState && this.clansSearchState.length > 1 && this.searchQuery) {
        const capitalizedQuery = upperFirst(this.searchQuery);
        return [
          {name_ch: capitalizedQuery, display: `${capitalizedQuery} (all translations)`},
          ...this.clansSearchState,
        ];
      }
      return this.clansSearchState;
    },
    defaultPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      return this.showDefaultLabel ? 'All Clan Surnames' : '';
    },
  },
  methods: {
    onOpen() {
      const alias = this.value && this.value.alias;
      const nameCh = this.value && this.value.name_ch;
      if (alias || (nameCh && !isChineseText(nameCh))) {
        return this.$refs['surname-input'].updateSearch(alias || nameCh);
      }
      return this.onSearchChange();
    },
    onClose() {
      this.clearClanOptions();
    },
    onSearchChange(query) {
      this.searchRan = false;
      this.searchQuery = query;
      this.$store.commit('setClansSearchState', []);
      if (query) {
        this.$store.commit('setClansSearchLoadingState', true);
        this.searchClan(query);
      }
    },
    searchClan: debounce(function (query) {
      this.$store.dispatch('searchClansAction', {q: query, limit: 20}).then(res => {
        this.$store.commit('mutateSearchAllSourcesOptionsFacetsState', {clans: res.objects});
        this.$store.commit('mutateSourcesTextSearchOptionsState', {clans: res.objects});
        this.searchRan = true;
      });
    }, 200),
    clearClanOptions() {
      this.$store.commit('setClansSearchState', []);
    },
    onClanSelect(value) {
      this.$emit('select', value);
    },
    clearClan() {
      this.$emit('select', {});
    },
    isMultiselectOpened() {
      return this.$refs['surname-input'] && this.$refs['surname-input'].isOpen;
    },
    getChineseNameDisplay(option) {
      if (option.name_hant && option.name_ch !== option.name_hant) {
        return `${option.name_ch} / ${option.name_hant}`;
      }
      return option.display || option.name_ch;
    },
    getEnglishNameDisplay(option) {
      let namesList = [option.pinyin];
      const pinyinLower = option.pinyin && option.pinyin.toLowerCase();
      const aliasLower = option.alias && option.alias.toLowerCase();
      if (
        pinyinLower &&
        aliasLower &&
        pinyinLower !== aliasLower &&
        option.name_ch !== aliasLower &&
        option.name_hant !== aliasLower
      ) {
        namesList.push(option.alias);
      }
      const names = namesList.join(' / ');
      return names ? names : '';
    },
  },
  components: {Multiselect, CloseIcon},
};
</script>

<style lang="scss" scoped>
.multiselect::v-deep {
  .multiselect__option,
  .multiselect__single {
    .english-name {
      color: $neutral-400;
      margin-left: 5px;
    }
  }
  .multiselect__option.multiselect__option--highlight {
    .english-name {
      color: white;
    }
  }
}
</style>
