function getChildrenForOption(selectedChildValue, allChildOptions, thisChildValues) {
  if (selectedChildValue) {
    return allChildOptions
      .filter(item => item.object_id === selectedChildValue)
      .map(item => ({...item, isSelected: true}));
  }
  return allChildOptions.filter(item => thisChildValues.includes(item.object_id));
}

export default {
  categoriesOptions() {
    if (!this.facets.category_id) {
      return [];
    }
    const allOptions = this.$store.getters.searchAllRecordsOptionsState;

    let options = Object.values(this.facets.category_id);
    const formCategoryId = this.formData.category_id;
    if (formCategoryId && !options.find(item => item.id === formCategoryId)) {
      const categories = allOptions && allOptions.categories ? allOptions.categories : [];
      const option = categories.find(c => c.id === formCategoryId) || {
        name: formCategoryId,
        explicit_name: formCategoryId,
        id: formCategoryId,
      };
      options = [{...option, count: 0, source_types: []}, ...options];
    }
    const sourceTypeOptions = this.facets.source_type;
    const formSourceType = this.formData[this.sourceTypeTypeName];

    let sourceTypeParentFound = false;
    for (let option of options) {
      const isSourceTypeParent = option.source_types.includes(formSourceType);
      if (option.id === this.formData.category_id || isSourceTypeParent) {
        option.children = getChildrenForOption(formSourceType, sourceTypeOptions, option.source_types);
        sourceTypeParentFound = sourceTypeParentFound || isSourceTypeParent;
      } else {
        option.children = [];
      }
    }

    if (!sourceTypeParentFound && formSourceType) {
      const sourceTypes = allOptions && allOptions.source_types ? allOptions.source_types : [];
      const option = sourceTypes.find(st => st.value === formSourceType) || {
        name: formSourceType,
        value: formSourceType,
      };
      options = [{...option, object_id: option.value, count: 0, isSelected: true}, ...options];
    }

    return this.normalizeOptions(options, 'category_id', 'id', 'priority', 'asc');
  },
  ancestralPlacesOptions() {
    if (!this.facets.ancestral_level_1_id) {
      return [];
    }
    let selectField = 'ancestral_level_3';
    let deselectFields = ['ancestral_place'];
    const selectedLevel1 = this.formData.ancestral_level_1 ? this.formData.ancestral_level_1.id : null;
    const selectedLevel2 = this.formData.ancestral_level_2 ? this.formData.ancestral_level_2.id : null;
    const selectedLevel3 = this.formData.ancestral_level_3 ? this.formData.ancestral_level_3.id : null;
    const ancestralPlaceId = this.formData.ancestral_place ? this.formData.ancestral_place.id : null;
    const ancestralPlaceItem = ancestralPlaceId
      ? {
          isSelected: true,
          name: this.formData.ancestral_place.address_en,
          object_id: ancestralPlaceId,
          count: this.countDisplay,
          ignoreCount: true,
          selectField,
          deselectFields,
        }
      : null;
    let selectedLevel1Present = false;
    let selectedLevel2Present = false;
    let selectedLevel3Present = false;
    let level4places =
      ancestralPlaceId && selectedLevel3 && ancestralPlaceId !== selectedLevel3 ? [ancestralPlaceItem] : [];
    let level3places = this.facets.ancestral_level_3_id.map(item => {
      const selected = item.object_id == selectedLevel3;
      selectedLevel3Present = selectedLevel3Present || selected;
      return {
        ...item,
        isSelected: selected && !level4places.length,
        children: selected ? level4places : [],
        selectField,
        deselectFields,
      };
    });
    level3places = this.normalizeOptions(level3places, 'ancestral_level_3_id');
    if (!selectedLevel3Present && selectedLevel3) {
      level3places.unshift({
        isSelected: true,
        name: this.formData.ancestral_level_3.address_en,
        object_id: selectedLevel3,
        count: 0,
        children: level4places,
        selectField,
        deselectFields,
      });
    }
    selectField = 'ancestral_level_2';
    deselectFields = ['ancestral_level_3', 'ancestral_place'];
    let level2places = this.facets.ancestral_level_2_id.map(item => {
      const selected = item.object_id == selectedLevel2;
      selectedLevel2Present = selectedLevel2Present || selected;
      return {
        ...item,
        isSelected: selected && !selectedLevel3,
        children: selected ? level3places : [],
        selectField,
        deselectFields,
      };
    });
    level2places = this.normalizeOptions(level2places, 'ancestral_level_2_id');
    if (!selectedLevel2Present && selectedLevel2) {
      level2places.unshift({
        isSelected: true,
        name: this.formData.ancestral_level_2.address_en,
        object_id: selectedLevel2,
        count: 0,
        children: level3places,
        selectField,
        deselectFields,
      });
    }
    selectField = 'ancestral_level_1';
    deselectFields = ['ancestral_level_3', 'ancestral_level_2', 'ancestral_place'];
    let level1places = this.facets.ancestral_level_1_id.map(item => {
      const selected = item.object_id == selectedLevel1;
      selectedLevel1Present = selectedLevel1Present || selected;
      return {
        ...item,
        isSelected: selected && !selectedLevel2,
        children: selected ? level2places : [],
        selectField,
        deselectFields,
      };
    });
    level1places = this.normalizeOptions(level1places, 'ancestral_level_1_id');
    if (!selectedLevel1Present && selectedLevel1) {
      level1places.unshift({
        isSelected: true,
        name: this.formData.ancestral_level_1.address_en,
        object_id: selectedLevel1,
        count: 0,
        children: level2places,
        selectField,
        deselectFields,
      });
    }
    if (
      ancestralPlaceId &&
      !level4places.length &&
      ancestralPlaceId !== selectedLevel1 &&
      ancestralPlaceId !== selectedLevel2 &&
      ancestralPlaceId !== selectedLevel3
    ) {
      const arr = level2places.length ? level2places : level1places;
      arr.unshift(ancestralPlaceItem);
    }
    return level1places;
  },
};
