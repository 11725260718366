<template>
  <modal :name="modalName" classes="clear_modal" :scrollable="true" height="auto">
    <div class="modal-content edit-search-content">
      <div class="heading">
        <h6 class="title">Refine your search</h6>
        <close-button class="dark" @click="hide"></close-button>
      </div>

      <slot :hide="hide" :activeTab="activeTab" :onValidationError="onValidationError"></slot>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

export default {
  props: {
    activeTab: String,
  },
  data() {
    return {
      modalName: 'edit-search',
    };
  },
  methods: {
    onValidationError() {
      this.$scrollTo('.has-error', {container: '.v--modal-overlay', offset: -80, force: true, cancelable: false});
    },
    hide() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {CloseButton},
  name: 'EditSearchModal',
};
</script>

<style lang="scss" scoped>
.modal-content.edit-search-content {
  min-width: 800px;
  width: 800px;
  background-color: $mcr-light-grey-tan;
  padding: 0;

  .heading {
    margin: 20px 40px 32px 16px;
    .title {
      margin: 0;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    min-width: auto;
    width: 100vw;
    overflow-x: hidden;
    padding-bottom: 70px;

    &::v-deep .advanced-form {
      .button-container {
        z-index: 5;
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 16px 10px;
        background: $background-light;
        border-top: 1px solid $neutral-200;

        .mcr-button {
          flex-grow: 1;
          border-radius: 0;
        }
        .mcr-button.white {
          display: none;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    width: 100vw;
    padding-top: 72px;

    .heading {
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 16px;
      text-align: center;
      background: $background-light;
      position: fixed;
      border-bottom: 1px solid $neutral-200;

      .close-btn {
        right: 3px;
        top: 3px;
        font-size: 35px;
      }
    }
  }
}
</style>
