<template>
  <metadata-table class="relatives-table" :rows="allRelatives" :is-classified="isClassified">
    <template v-slot:title>
      <span>Relatives</span>
    </template>
    <template v-slot:cell-name="{data: person}">
      <div v-if="isClassified" :class="{concealed: isClassified}">{{ person.full_name }}</div>
      <template v-else>
        <div v-if="person.code">
          <router-link :to="getPersonRoute(person)">{{ person.full_name || 'Unknown' }}</router-link>
        </div>
        <div v-else>{{ person.full_name }}</div>
      </template>
    </template>
    <template v-slot:cell-value="{data: person}">
      <span>{{ person.relation }} {{ person.relation_type }}</span>
    </template>
  </metadata-table>
</template>

<script>
import MetadataTable from '@common/elements/layouts/MetadataTable';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    relatives: Array,
    relativesJson: Array,
    isClassified: {type: Boolean, default: false},
  },
  computed: {
    allRelatives() {
      return [...(this.relatives || []), ...this.getRelativesJsonFormatted()];
    },
    locale() {
      return 'en';
    },
  },
  methods: {
    getRelativesJsonFormatted() {
      return this.relativesJson
        ? this.relativesJson.map(item => {
            return {...item, full_name: this.getRelativeName(item)};
          })
        : [];
    },
    getPersonRoute(person) {
      return getPersonRecordDetailRoute(person.code, person.full_name);
    },
    getRelativeName(relative) {
      const firstNames = relative.first_name ? [{value: relative.first_name}] : [];
      const surnames = relative.surname ? [{value: relative.surname}] : [];
      return getFullNameEn(firstNames, surnames) || getFullNameCn(firstNames, surnames);
    },
  },
  components: {MetadataTable},
  name: 'RelativesTable',
};
</script>

<style lang="scss" scoped>
.relatives-table {
  .table {
    .table-row {
      .cell {
        .start-event,
        .end-event {
          color: $neutral-400;
          display: flex;
        }
      }
    }
  }
}
</style>
