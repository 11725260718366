<template>
  <div>
    <div class="readable_content">
      <h1>This source is currently unavailable.</h1>
      <p>This source and all of its records are currently unavailable.</p>
      <p>
        If you would like to access this source, you may enquire on its current state by emailing
        <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>
      </p>

      <p><a @click="goBack" class="back-button">Go Back</a></p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Record Taken Offline',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.back-button {
  &:before {
    content: '\2039';
    margin-right: 5px;
  }
}
</style>
