<template>
  <modal
    :name="modalName"
    overlay-transition="slide"
    transition="slide"
    classes="clear_modal filters-container-mobile"
    :scrollable="true"
    height="auto"
  >
    <div class="modal-content">
      <slot :hide="hide" />

      <close-button @click="hide"></close-button>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

export default {
  data() {
    return {
      modalName: 'filters-mobile',
    };
  },
  methods: {
    hide() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {CloseButton},
};
</script>

<style lang="scss" scoped>
.filters-container-mobile .modal-content {
  transition: all 1s ease;
  min-width: auto;
  width: calc(100vw - 100px);

  .title {
    margin-bottom: 25px;
    border-bottom: 1px solid $divider-line-color;
    padding-bottom: 15px;
  }

  &::v-deep .heading {
    padding-right: 40px;
  }

  &::v-deep .filters-container .heading {
    .clear-all {
      display: flex;
    }
    .edit-search {
      display: none;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    width: calc(100vw - 50px);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
</style>
