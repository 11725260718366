<template>
  <div class="filters-full-text">
    <div class="heading">
      <div class="bold">Filters</div>
      <div class="clear-all" v-if="showClear"><a @click="clearAllFilters">Clear All</a></div>
      <div class="edit-search"><a @click="$emit('edit-search')">Advanced</a></div>
    </div>
    <div v-if="noFilters" class="supplemental">No filters to apply.</div>
    <filter-item
      label="All Categories"
      :options="categoriesOptions"
      :cut-count="filterCutCount"
      :hide-header-count="true"
      @select="selectTypeCategory"
      @deselect="deSelectTypeCategory"
    ></filter-item>

    <filter-item
      label="All Places"
      :options="autoPlaceOptions"
      @select="selectTypeAutoPlace"
      @deselect="deSelectTypeAutoPlace"
    ></filter-item>

    <filter-item
      label="All Dates"
      :options="datesOptions"
      :total-count="facets.record_year && facets.record_year.total_count"
      :cut-count="100"
      @select="selectTypeDates"
      @deselect="deSelectTypeDates"
    ></filter-item>

    <filter-item
      label="All Genders"
      :options="genderOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'gender')"
      @deselect="deSelectType('gender')"
    ></filter-item>

    <filter-item
      label="All Chinese Surnames"
      :options="clansOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectType($event.name_hant || $event.name_ch || $event.object_id, 'clan_name')"
      @deselect="deSelectType('clan_name')"
    ></filter-item>

    <filter-item
      label="All Image Availabilities"
      :options="hasImagesOptions"
      :total-count="facets.has_images && facets.has_images.total_count"
      :cut-count="100"
      @select="selectType($event.object_id, 'has_images')"
      @deselect="deSelectType('has_images')"
    ></filter-item>
  </div>
</template>

<script>
import {getClanName} from '@common/pages/searches/helpers/utils';
import consts from '@common/utils/consts';
import {HAS_IMAGES_OPTIONS} from '@common/utils/consts.search';
import {isChineseText} from '@common/utils/utils';
import isEmpty from 'lodash/isEmpty';

import FilterItem from './FilterItem';
import commonFiltersComputed from './commonFiltersComputed';
import commonFiltersMethods from './commonFiltersMethods';

export default {
  props: {
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    sourceCountries: Array,
    formData: Object,
    totalCount: Number,
    showClear: Boolean,
  },
  data() {
    return {
      filterCutCount: 5,
      sourceTypeTypeName: 'source_types',
    };
  },
  computed: {
    ...commonFiltersComputed,
    countDisplay() {
      return this.totalCount >= 10000 ? '10000+' : this.totalCount;
    },
    autoPlaceOptions() {
      if (!this.facets.place_ids_level_0) {
        return [];
      }
      if (isEmpty(this.formData.auto_place)) {
        return this.facets.place_ids_level_0;
      }
      if (this.formData.auto_place && !this.formData.auto_place.object_id && this.formData.auto_place.address_en) {
        const selected = {
          name: this.formData.auto_place.address_en,
          object_id: null,
          isSelected: true,
          count: this.totalCount,
        };
        return [selected];
      }

      const facetsByLevel = {
        0: [...this.facets.place_ids_level_0],
        1: [...this.facets.place_ids_level_1],
        2: [...this.facets.place_ids_level_2],
        3: [...this.facets.place_ids_level_3],
      };

      let hierarchy = [...(this.formData.auto_place.parents || []), this.formData.auto_place];
      hierarchy = hierarchy.toSorted((a, b) => a.level - b.level);
      let topPlace = null;
      let parentPlace = null;

      for (let level = 0; level < hierarchy.length; level++) {
        let levelPlace = hierarchy[level];
        levelPlace.object_id = levelPlace.object_id || levelPlace.id;
        const levelFacets = facetsByLevel[level.toString()] || [];
        let facetPlace = levelFacets.find(item => item.object_id.toString() === levelPlace.object_id.toString());
        facetPlace = facetPlace || {
          ...levelPlace,
          name: levelPlace.address_en,
          object_id: levelPlace.object_id,
          count: this.totalCount,
        };
        if (parentPlace) {
          parentPlace.children = [facetPlace];
          facetPlace.parent = parentPlace;
          facetPlace.parents = [parentPlace, ...(parentPlace.parents || [])];
        }
        topPlace = topPlace || [facetPlace];
        parentPlace = facetPlace;
        if (level === hierarchy.length - 1) {
          const nextLevelFacets = facetsByLevel[level + 1];
          const children = (nextLevelFacets || [])
            .filter(item => item.parent_id && item.parent_id.toString() === facetPlace.object_id.toString())
            .map(item => {
              return {...item, parent: facetPlace, parents: [facetPlace, ...(facetPlace.parents || [])]};
            });
          facetPlace.children = children;
          facetPlace.isSelected = true;
        }
      }
      return topPlace;
    },
    genderOptions() {
      if (!this.facets.gender) {
        return [];
      }
      const mapping = consts.GENDERS;
      let options = this.facets.gender.map(gender => {
        return {...gender, name: mapping[gender.object_id], isSelected: this.isTypeItemSelected(gender, 'gender')};
      });
      const formGender = this.formData.gender;
      if (formGender && !options.find(item => item.object_id === formGender)) {
        options = [{name: mapping[formGender], object_id: formGender, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'gender', 'object_id', 'object_id', 'desc');
    },
    noneName() {
      return 'None';
    },
    noFilters() {
      return (
        !this.autoPlaceOptions.length &&
        !this.datesOptions.length &&
        !this.categoriesOptions.length &&
        !this.genderOptions.length &&
        !this.clansOptions.length &&
        !this.hasImagesOptions.length
      );
    },
    clansOptions() {
      if (!this.facets.clans) {
        return [];
      }
      let options = this.facets.clans.map(clan => {
        return {...clan, name: getClanName(clan)};
      });
      const formClanName = this.formData.clan_name;
      if (formClanName && !options.find(item => this.isTypeItemClanNameSelected(item, formClanName))) {
        const nameDisplay = isChineseText(formClanName) ? formClanName : `${formClanName} (All Translations)`;
        options = [{name_ch: formClanName, name: nameDisplay, count: this.countDisplay, ignoreCount: true}, ...options];
      }
      return this.normalizeOptions(options, 'clan_name', 'name_ch', 'count', 'desc');
    },
    datesOptions() {
      if (!this.facets.record_year) {
        return [];
      }
      const selectedYear = this.formData.record_year;

      let isSelectedPresent = false;
      let centuries = [...this.facets.record_year.items];

      centuries = centuries.map(century => {
        let decades = century.children || [];
        let expandCentury = selectedYear == century.object_id;
        decades = decades.map(childDecade => {
          let expandDecade = selectedYear == childDecade.object_id;
          expandCentury = expandCentury || expandDecade;
          let years = childDecade.children || [];
          years = years.map(childYear => {
            let yearSelected = selectedYear == childYear.object_id;
            expandCentury = expandCentury || yearSelected;
            expandDecade = expandDecade || yearSelected;
            return {...childYear, isSelected: yearSelected};
          });
          years = this.normalizeOptions(years, 'record_year', 'object_id', 'key', 'asc');
          return {
            ...childDecade,
            isSelected: selectedYear == childDecade.object_id,
            children: expandDecade ? years : [],
          };
        });
        decades = this.normalizeOptions(decades, 'record_year', 'object_id', 'key', 'asc');
        isSelectedPresent = isSelectedPresent || expandCentury;
        return {...century, isSelected: selectedYear == century.object_id, children: expandCentury ? decades : []};
      });

      if (!isSelectedPresent && selectedYear) {
        const value = selectedYear;
        const name = value.includes('-') ? value.split('-')[1] + 's' : value;
        centuries.unshift({isSelected: true, name, object_id: value, count: 0});
      }
      return this.normalizeOptions(centuries, 'record_year', 'object_id', 'key', 'asc');
    },
    hasImagesOptions() {
      if (!this.facets.has_images) {
        return [];
      }
      const mapping = HAS_IMAGES_OPTIONS;
      let options = this.facets.has_images.map(item => {
        return {...item, name: mapping[item.object_id], isSelected: this.isTypeItemSelected(item, 'has_images')};
      });
      const formData = this.formData.has_images;
      if (formData && !options.find(item => item.object_id === formData)) {
        options = [{name: mapping[formData], object_id: formData, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'has_images', 'object_id', 'name', 'asc');
    },
  },
  methods: {
    ...commonFiltersMethods,
    compareDates(a, b) {
      if (a.key === 'none') {
        return -1;
      }
      return a.key - b.key;
    },
    isTypeItemSelected(item, typeName) {
      const mapping = {
        clan_name: this.isTypeItemClanNameSelected,
        source_location: this.isTypeItemPlaceSelected,
        category_id: this.isTypeItemByIdSelected,
      };
      const method = mapping[typeName];
      const selectedValue = this.formData[typeName];
      return method ? method(item, selectedValue) : item.object_id == selectedValue;
    },
    isTypeItemClanNameSelected(item, selectedValue) {
      if (!selectedValue) {
        return false;
      }
      return ['name_ch', 'name_hant', 'object_id'].some(field => item[field] === selectedValue);
    },
    isTypeItemByIdSelected(item, selectedValue) {
      return selectedValue ? selectedValue === item.id : false;
    },
    isTypeItemPlaceSelected(item, selectedValue) {
      return selectedValue ? item.object_id == selectedValue.id : false;
    },
    selectTypeSourceLocation(value) {
      return this.selectType(
        {address_en: value.name, full_address_en: value.name, id: value.object_id},
        'source_location'
      );
    },
    selectTypeAutoPlace(value) {
      const place = {
        address_en: value.name,
        full_address_en: value.name,
        id: value.object_id,
        level: value.level,
        level_name: value.level_name,
        parents: value.parents,
      };
      this.selectType(place, 'auto_place');
    },
    deSelectTypeAutoPlace() {
      this.deSelectType('auto_place');
    },
    selectTypeDates(value) {
      this.selectType(value.object_id, 'record_year');
    },
    deSelectTypeDates(value) {
      this.deSelectType('record_year');
    },
    clearAllFilters() {
      this.selectType(null, null, [
        'category_id',
        'source_types',
        'source_location',
        'clan_name',
        'record_year',
        'has_images',
        'gender',
      ]);
    },
  },
  components: {FilterItem},
  name: 'FiltersIndexed',
};
</script>

<style scoped></style>
