<template>
  <div class="image-physical-copy">
    <div class="item"><img :src="$getAsset('/assets/mcr-logo-grey-light.png')" width="150" /></div>
    <div class="item supplemental">Physical Record Only</div>
  </div>
</template>

<script>
export default {
  name: 'ImagePhysicalCopy',
};
</script>

<style scoped lang="scss">
.image-physical-copy {
  width: 100%;
  height: 100%;
  background-color: $mcr-light-grey-tan;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
</style>
