<template>
  <stand-out-block v-show="show">
    <div class="explore-zupus">
      <div>{{ browseZupusText }}</div>
      <mcr-button-router-link class="fat" :to="zupusRoute">Explore</mcr-button-router-link>
    </div>
  </stand-out-block>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  watch: {
    lastName(value) {
      this.loadZupusCount(value);
    },
  },
  created() {
    this.loadZupusCount(this.lastName);
  },
  props: {
    lastName: String,
    loading: Boolean,
  },
  data() {
    return {
      lastNameToCount: {},
    };
  },
  computed: {
    show() {
      return !this.loading && !!this.browseZupusText;
    },
    browseZupusText() {
      const count = this.lastNameToCount[this.lastName];
      return count && this.lastName ? `Browse ${count} ${this.lastName} zupus (family tree books)` : '';
    },
    zupusRoute() {
      return {name: 'search-tool-zupu', query: {clan_name: this.lastName, tab: TAB_ID_SOURCES}};
    },
  },
  methods: {
    loadZupusCount(value) {
      if (value && !this.lastNameToCount[value]) {
        this.$store.dispatch('searchClanPedigreesAction', {clan_name: value, only_meta: true}).then(response => {
          this.$set(this.lastNameToCount, value, response.meta.total_count);
        });
      }
    },
  },
  components: {StandOutBlock, McrButtonRouterLink},
  name: 'BlockBrowseZupus',
};
</script>

<style lang="scss" scoped>
.stand-out-block {
  .explore-zupus {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;

    div {
      flex-grow: 1;
      font-size: 1.2em;
      margin-right: 20px;
      margin-top: 20px;
    }

    .mcr-button {
      flex-grow: 1;
      max-width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
