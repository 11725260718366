<template>
  <div class="search-all-records-page">
    <div v-if="initialLoading"><mcr-loading-indicator :loading="true"></mcr-loading-indicator></div>
    <div v-else :class="mainWrapperClass">
      <h4 class="main-title" v-if="mainTitle">{{ mainTitle }}</h4>

      <div v-if="searchRan">
        <your-search-form
          :active-tab="activeTab"
          :options="options"
          :facets="searchMeta.facets || {}"
          :form-data="activeForm"
          :total-count="searchMeta.total_count"
          :is-loading="loading"
          @submit="submitYourSearch"
          @click-edit="onEditSearchClick"
        ></your-search-form>
        <div class="search-content-wrapper">
          <div class="search-controls" v-if="isDesktop">
            <filters-container
              ref="filters"
              :active-tab="activeTab"
              :form-data="activeForm"
              :facets="searchMeta.facets || {}"
              :none-value="searchMeta.emtpy_filter_value"
              :source-types="sourceTypes"
              :source-countries="searchMeta.source_countries"
              :total-count="searchMeta.total_count"
              :applied-filters-count="appliedFiltersCount"
              @select="onFilterSelect"
              @edit-search="onEditSearchClick"
            ></filters-container>
          </div>

          <div class="search-content search-results-content">
            <mcr-loading-indicator v-if="loading" :loading="true" class="results-loading"></mcr-loading-indicator>
            <search-error v-else-if="searchError" :text="searchErrorText"></search-error>
            <template v-else>
              <sticky-edit-buttons
                v-if="!isDesktop"
                :show-filters="showFilters"
                :applied-filters-count="appliedFiltersCount"
                @click-edit-search="onEditSearchClick"
                @click-filters="onEditFiltersClick"
              ></sticky-edit-buttons>
              <search-results
                v-if="resultsFound"
                :active-tab="activeTab"
                :filters="filters"
                :list="resultsList"
                :options="options"
                :meta="searchMeta"
                :title="mainTitleMainPart"
                @switch-page="onSwitchPage"
              >
                <cta-block-compare-plans class="top-cta" slot="top-cta"></cta-block-compare-plans>
              </search-results>
              <search-results-helper
                :active-tab="activeTab"
                :results-count="searchMeta.total_count"
                :first-name="firstName"
                :last-name="lastName"
                @edit-search="onEditSearchClick"
              ></search-results-helper>
            </template>
            <block-browse-zupus :loading="loading" :last-name="lastName"></block-browse-zupus>
          </div>
        </div>
      </div>

      <div class="initial-state" v-else>
        <div class="advanced-toggler desktop">
          <span @click="toggleAdvancedSearch">{{ advancedSearchTogglerText }}</span>
        </div>
        <form-tabs-block-container
          ref="tabs"
          :start-tab="activeTab"
          :init-advanced="showAdvancedSearch"
          :show-all-tabs="!$route.query.category_id"
          @submit="onSubmitFromInitState"
          @validation-error="onValidationError"
          @activate-tab="onInitStateActivateTab"
        >
          <div class="advanced-toggler mobile">
            <span @click="toggleAdvancedSearch">{{ advancedSearchTogglerText }}</span>
          </div>
        </form-tabs-block-container>

        <category-details></category-details>

        <cta-block-compare-plans></cta-block-compare-plans>
      </div>
    </div>

    <filters-container-mobile>
      <filters-container
        slot-scope="props"
        :active-tab="activeTab"
        :form-data="activeForm"
        :facets="searchMeta.facets || {}"
        :none-value="searchMeta.emtpy_filter_value"
        :source-types="sourceTypes"
        :source-countries="searchMeta.source_countries"
        :total-count="searchMeta.total_count"
        :applied-filters-count="appliedFiltersCount"
        @select="
          props.hide();
          onFilterSelect($event);
        "
      ></filters-container>
    </filters-container-mobile>
    <edit-search-modal :active-tab="activeTab">
      <form-tabs-block-container
        slot-scope="props"
        :init-advanced="true"
        :buttons-mobile-sticky="true"
        :show-cancel="true"
        :show-all-tabs="true"
        :start-tab="props.activeTab"
        :clear-forms="isClearEditSearch"
        @submit="
          props.hide($event);
          onSubmitFromEditSearch($event);
        "
        @cancel="props.hide"
        @validation-error="props.onValidationError"
      ></form-tabs-block-container>
    </edit-search-modal>
  </div>
</template>

<script>
import CategoryDetails from '@common/pages/searches/baseSearchAll/CategoryDetails';
import searchAllRecordsPage from '@common/pages/searches/baseSearchAll/searchAllRecordsPage';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

export default {
  created() {
    if (this.$store.getters.userIsLoggedInState) {
      this.$store.dispatch('getSearchHintsBannerAction');
    }
  },
  mixins: [searchAllRecordsPage],
  components: {
    CtaBlockComparePlans,
    CategoryDetails,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/style/search-tool-list-tabs.scss';
@import '~@common/pages/searches/styles/search-page.scss';

.engaging-stand-out-block.top-cta {
  margin-top: 20px;
}
</style>
