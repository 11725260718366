import { render, staticRenderFns } from "./SearchError.vue?vue&type=template&id=634e0a0f&scoped=true&"
import script from "./SearchError.vue?vue&type=script&lang=js&"
export * from "./SearchError.vue?vue&type=script&lang=js&"
import style0 from "./SearchError.vue?vue&type=style&index=0&id=634e0a0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634e0a0f",
  null
  
)

export default component.exports