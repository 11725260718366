<template>
  <div class="section-more-records">
    <h5>Explore other records matching "{{ fullName }}"</h5>
    <router-link :to="searchAllRoute">
      View {{ mentionsCount }} potential records matching "{{ fullName }}"</router-link
    >
  </div>
</template>

<script>
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';

export default {
  props: {
    firstNameEn: String,
    firstNameCh: String,
    surnameEn: String,
    surnameCh: String,
    mentionsCount: {
      type: Number,
      optional: true,
    },
  },
  computed: {
    fullName() {
      return this.surnameEn ? `${this.firstNameEn} ${this.surnameEn}` : `${this.surnameCh}${this.firstNameCh}`;
    },
    searchAllRoute() {
      let query = this.surnameEn
        ? {surname: this.surnameEn, first_name: this.firstNameEn}
        : {surname: this.surnameCh, first_name: this.firstNameCh};
      query.tab = TAB_ID_INDEXED_RECORDS;
      return {name: 'search-all-records', query};
    },
  },
  methods: {},
  name: 'SectionMoreRecords',
};
</script>

<style lang="scss" scoped>
.section-more-records {
  margin-bottom: 10px;
  a {
    font-weight: bold;
  }
}
</style>
