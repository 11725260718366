<template>
  <div class="relaxed-search-disclaimer" v-if="meta && meta.is_relaxed_search && meta.total_count">
    <div class="results-empty-wrapper">
      <book-icon :size="36"></book-icon>
      <h5>No exact matches found for "{{ name }}"</h5>
    </div>
    <h6 class="similar-records">Similar records to "{{ name }}"</h6>
  </div>
</template>

<script>
import BookIcon from 'vue-material-design-icons/BookSearchOutline';

export default {
  props: {
    meta: Object,
    firstName: String,
    lastName: String,
  },
  computed: {
    name() {
      return this.lastName && this.firstName ? `${this.lastName}, ${this.firstName}` : this.lastName || this.firstName;
    },
  },
  components: {BookIcon},
  name: 'RelaxedSearchDisclaimer',
};
</script>

<style lang="scss" scoped>
.relaxed-search-disclaimer {
  padding-top: 8px;

  .results-empty-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid $neutral-300;
    padding-top: 20px;
    padding-bottom: 40px;

    .material-design-icon {
      color: $neutral-500;
    }

    h5 {
      margin: 8px 0 0 0;
      color: $neutral-600;
    }
  }

  .similar-records {
    margin: 24px 0 16px;
    color: $neutral-600;
  }
}
</style>
