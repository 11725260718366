<template>
  <div class="facts-form-block sub-forms-block">
    <div class="section-heading text-lg">Other Events</div>

    <fact-form
      v-for="fact in factForms"
      :key="fact.formId"
      :label="fact.factType.label"
      :fields-list="fact.factType.fields_list"
      :fact="fact"
      @change-value="onFactChange(fact, 'value', $event)"
      @change-year="onFactChange(fact, 'year', $event)"
      @change-place="onFactChange(fact, 'place', $event)"
      @change-cemetery="onFactChange(fact, 'cemetery', $event)"
      @remove="$emit('remove-fact', fact)"
      @submit="$emit('submit')"
    ></fact-form>

    <div class="fact-types form-options">
      <div class="chip" v-for="item in allOptions" :key="item.id" @click="addFactForm(item)">
        <div>{{ item.label }}</div>
        <plus-icon class="plus-icon" :size="16"></plus-icon>
      </div>
    </div>
  </div>
</template>

<script>
import FactForm from '@common/pages/searches/forms/FactForm';
import {FACT_CATEGORY_IMMIGRATION} from '@common/utils/consts.search';
import PlusIcon from 'vue-material-design-icons/Plus';

const ANY_EVENT_TYPE = 'any';

export default {
  props: {
    options: Array,
    facts: Array,
  },
  computed: {
    anyEventType() {
      return {
        id: ANY_EVENT_TYPE,
        label: 'Any Event',
        fields_list: ['value', 'place', 'start_date'],
      };
    },
    allOptions() {
      return [this.anyEventType, ...this.options];
    },
    factForms() {
      return this.facts
        .filter(fact => fact.fact_category !== FACT_CATEGORY_IMMIGRATION)
        .map(fact => {
          let factType = this.allOptions.find(option => option.id === fact.fact_type);
          if (!factType) {
            factType = fact.fact_type
              ? {...this.anyEventType, id: fact.fact_type, label: fact.fact_type}
              : this.anyEventType;
          }
          return {...fact, factType};
        });
    },
  },
  methods: {
    addFactForm(option) {
      const factType = ANY_EVENT_TYPE === option.id ? '' : option.id;
      this.$emit('add-fact', factType);
    },
    onFactChange(fact, fieldName, newValue) {
      this.$emit('change-fact', {fact, fieldName, newValue});
    },
  },
  components: {FactForm, PlusIcon},
  name: 'FactsFormBlock',
};
</script>

<style lang="scss" scoped>
.facts-form-block {
}
</style>
