<template>
  <div class="relatives-form-block sub-forms-block">
    <div class="section-heading text-lg">Family Members</div>

    <relative-form
      v-for="relative in relativeForms"
      :key="relative.formId"
      :label="getLabel(relative.relation_type)"
      :relative="relative"
      @change-first-name="onRelativeChange(relative, 'first_name', $event)"
      @change-surname="onRelativeChange(relative, 'surname', $event)"
      @remove="$emit('remove-relative', relative)"
      @submit="$emit('submit')"
    ></relative-form>

    <div class="form-options">
      <div class="chip" v-for="item in options" :key="item.id" @click="addRelativeForm(item)">
        <div>{{ item.label }}</div>
        <plus-icon class="plus-icon" :size="16"></plus-icon>
      </div>
    </div>
  </div>
</template>

<script>
import RelativeForm from '@common/pages/searches/forms/RelativeForm';
import {RELATIVES_OPTIONS} from '@common/utils/consts.search';
import PlusIcon from 'vue-material-design-icons/Plus';

export default {
  props: {
    relatives: Array,
  },
  computed: {
    relativeForms() {
      return this.relatives;
    },
    options() {
      return RELATIVES_OPTIONS;
    },
  },
  methods: {
    getLabel(relationType) {
      const option = this.options.find(op => op.id === relationType);
      return option ? option.label : 'Relative';
    },
    addRelativeForm(option) {
      this.$emit('add-relative', option.id);
    },
    onRelativeChange(relative, fieldName, newValue) {
      this.$emit('change-relative', {relative, fieldName, newValue});
    },
  },
  components: {RelativeForm, PlusIcon},
  name: 'RelativesFormBlock',
};
</script>

<style lang="scss" scoped></style>
