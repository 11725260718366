import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';

function hasQueryDifferentValues(filterKey, filterValue, routeQuery) {
  const queryStartsWithMapping = {facts: 'fact', relatives: 'relative'};
  const queryStartsWith = queryStartsWithMapping[filterKey];
  const comparedFactKeys = [];
  for (let [factKey, factValue] of Object.entries(filterValue || {})) {
    comparedFactKeys.push(factKey);
    if (routeQuery[factKey] != factValue) {
      return true;
    }
  }
  if (
    Object.keys(routeQuery).some(
      queryKey => queryKey.startsWith(queryStartsWith) && !comparedFactKeys.includes(queryKey)
    )
  ) {
    return true;
  }
}

export default {
  isQueryParamsApplied() {
    for (const [key, value] of Object.entries(this.filters)) {
      if (key === 'facts' || key === 'relatives') {
        if (hasQueryDifferentValues(key, value, this.$route.query)) {
          return false;
        }
        continue;
      }

      // if value is boolean, check query param is 'true', any other query value is considered false
      if (typeof value === 'boolean') {
        if (value && this.$route.query[key] !== 'true') {
          return false;
        }
      }
      // use != instead of !== as query params values are strings
      else if ((value || this.$route.query[key]) && this.$route.query[key] != value) {
        return false;
      }
    }
    return this.queryParamsPageIsDisplayed;
  },
  queryParamsPageIsDisplayed() {
    const pageNumber = parseInt(this.$route.query.page || 1);
    if (!this.searchMeta.limit) {
      return pageNumber === 1;
    }
    return pageNumber === this.searchMeta.offset / this.searchMeta.limit + 1;
  },
  isDesktop() {
    return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
  },
  advancedSearchTogglerText() {
    return this.showAdvancedSearch ? 'Simple Search' : 'Advanced Search';
  },
  resultsFound() {
    return this.searchMeta.total_count;
  },
  mainWrapperClass() {
    return this.searchRan ? 'limited_content' : 'readable_content';
  },
  appliedFiltersCount() {
    return [
      this.filters.country_id,
      this.filters.source_types,
      this.filters.gender,
      this.filters.ancestral_place_id,
      this.filters.ancestral_level_1_ids,
      this.filters.ancestral_level_2_ids,
      this.filters.ancestral_level_3_ids,
      this.filters.clan_name,
      this.filters.record_year,
      this.filters.country,
      this.filters.source_type,
      this.filters.category_id,
      this.filters.record_format,
      this.filters.has_ocr,
      this.filters.has_images,
    ].filter(val => !!val).length;
  },
  registrationWallText() {
    const resultsCount = this.searchMeta.total_count;
    let clanName = this.filters.clan_name === 'none' ? '' : this.filters.clan_name;
    const name =
      this.activeTab === TAB_ID_INDEXED_RECORDS
        ? `${this.filters.first_name || ''} ${this.filters.surname || ''}`.trim() || 'your search'
        : `${this.filters.q || ''} ${clanName || ''}`.trim() || 'your search';
    return `Found ${resultsCount} records for ${name}. Create an account to continue.`;
  },
};
