<template>
  <tabs-block ref="tabs" :start-tab="startTab" @activate-tab="onActivateTab">
    <tabs-block-item
      :visible="showAllTabs || searchCategoryState.has_indexed_form"
      name="Indexed Records"
      name-mobile="Indexed"
      :identifier="TAB_ID_INDEXED_RECORDS"
      :priority="1"
    >
      <div class="beta-disclaimer supplemental" v-if="isZupusCategory">
        Indexed search for zupus is in beta. Use <a @click="goToFullTextTab">Full Text Search</a> for full coverage of
        zupus.
      </div>
      <indexed-records-form
        ref="indexed-records-form"
        :init-advanced="initAdvanced"
        :init-lang="isCategoryPage ? searchCategoryState.default_language : 'en'"
        :show-cancel="showCancel"
        :show-lang-selector="!isZupusCategory"
        :is-simple-form-small="isZupusCategory"
        :clear-form="clearForms"
        @submit="onSubmit({tab: TAB_ID_INDEXED_RECORDS})"
        @validation-error="onValidationError"
        @cancel="onCancel"
      ></indexed-records-form>
      <slot></slot>
    </tabs-block-item>
    <tabs-block-item
      :visible="showAllTabs || searchCategoryState.has_full_text_form"
      name="Full Text"
      :identifier="TAB_ID_FULL_TEXT"
      :priority="2"
    >
      <full-text-form
        ref="full-text-form"
        :init-advanced="initAdvanced"
        :init-lang="isCategoryPage ? searchCategoryState.default_language : 'en'"
        :show-cancel="showCancel"
        :show-lang-selector="!isZupusCategory"
        :clear-form="clearForms"
        @submit="onSubmit({tab: TAB_ID_FULL_TEXT})"
        @validation-error="onValidationError"
        @cancel="onCancel"
      ></full-text-form>
      <slot></slot>
    </tabs-block-item>
    <tabs-block-item
      :visible="showAllTabs || searchCategoryState.has_sources_form"
      :name="sourcesTabName"
      :identifier="TAB_ID_SOURCES"
      :priority="3"
    >
      <sources-form
        ref="sources-form"
        :init-form-data="searchAllSourcesFormState"
        :meta="searchAllSourcesOptionsState.facets"
        :source-types="sourceTypes"
        :init-advanced="initAdvanced"
        :show-cancel="showCancel"
        :is-zupus-category="isZupusCategory"
        :clear-form="clearForms"
        @submit="onSubmit({form: $event, tab: TAB_ID_SOURCES})"
        @validation-error="onValidationError"
        @cancel="onCancel"
      ></sources-form>
      <slot></slot>
    </tabs-block-item>
  </tabs-block>
</template>

<script>
import TabsBlock from '@common/elements/tabs/TabsBlock';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import SourcesForm from '@common/pages/searches/forms/SourcesForm';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {mapGetters} from 'vuex';

import FullTextForm from '../forms/FullTextForm';
import IndexedRecordsForm from '../forms/IndexedRecordsForm';

export default {
  props: {
    startTab: String,
    initAdvanced: {type: Boolean, default: false},
    showCancel: {type: Boolean, default: false},
    showAllTabs: {type: Boolean, default: false},
    clearForms: {type: Boolean, default: false},
  },
  data() {
    return {
      TAB_ID_INDEXED_RECORDS,
      TAB_ID_FULL_TEXT,
      TAB_ID_SOURCES,
    };
  },
  computed: {
    ...mapGetters([
      'searchAllRecordsOptionsState',
      'searchAllSourcesFormState',
      'searchAllSourcesOptionsState',
      'searchCategoryState',
    ]),
    fromFullTextToIndexedFields() {
      return {
        ...this.formFiltersShared,
        q: 'first_name',
        last_name: 'surname',
        // country: 'country_id',
        // source_type: 'source_types',
      };
    },
    getValueMapping() {
      return {
        // country: this.getCountryName,
        // country_id: this.getCountryId,
      };
    },
    fromIndexedToFullTextFields() {
      let ret = {...this.formFiltersShared};
      for (let key in this.fromFullTextToIndexedFields) {
        ret[this.fromFullTextToIndexedFields[key]] = key;
      }
      return ret;
    },
    formFiltersShared() {
      return {category_id: 'category_id'};
    },
    sourceTypes() {
      return this.searchAllSourcesOptionsState.facets ? this.searchAllSourcesOptionsState.facets.source_type : null;
    },
    isCategoryPage() {
      return this.searchCategoryState && this.$route.query.category_id === this.searchCategoryState.id;
    },
    isZupusCategory() {
      return this.$route.query.category_id === sourceTypeCategories.CATEGORY_ZUPUS;
    },
    sourcesTabName() {
      return this.isZupusCategory ? 'Books' : 'Sources';
    },
  },
  methods: {
    onActivateTab({tabId, fromTabId}) {
      this.trackActivateTab(tabId);
      this.$emit('activate-tab', {tabId, fromTabId});
      if (!fromTabId || !tabId) {
        return;
      }
      if (tabId === this.TAB_ID_FULL_TEXT && fromTabId === this.TAB_ID_INDEXED_RECORDS) {
        return this.mutateTabForm(
          this.fromIndexedToFullTextFields,
          this.$refs['indexed-records-form'].formData,
          this.$refs['full-text-form']
        );
      }
      if (tabId === this.TAB_ID_INDEXED_RECORDS && fromTabId === this.TAB_ID_FULL_TEXT) {
        return this.mutateTabForm(
          this.fromFullTextToIndexedFields,
          this.$refs['full-text-form'].formData,
          this.$refs['indexed-records-form']
        );
      }
      return this.mutateTabForm(this.formFiltersShared, this.getRef(fromTabId).formData, this.getRef(tabId));
    },
    getRef(tabId) {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.$refs['indexed-records-form'],
        [TAB_ID_FULL_TEXT]: this.$refs['full-text-form'],
        [TAB_ID_SOURCES]: this.$refs['sources-form'],
      };
      return mapping[tabId];
    },
    trackActivateTab(tab) {
      AnalyticsMainHandler.trackSearchTabClick(tab, getRoutePageName(this.$route));
    },
    mutateTabForm(mapping, formData, form) {
      if (!form) {
        return;
      }
      let newData = {};
      for (let [key, newKey] of Object.entries(mapping)) {
        newData[newKey] = this.getValue(newKey, formData[key]);
      }
      form.mutateFormData(newData);
      form.initSwitchLang();
    },
    getValue(newKey, newValue) {
      const method = this.getValueMapping[newKey];
      return method ? method(newValue) || newValue : newValue;
    },
    getCountryName(countryId) {
      const countries = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_countries;
      const country = countries ? countries.find(c => c.id === countryId) : null;
      return country && country.name;
    },
    getCountryId(countryName) {
      const countries = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_countries;
      const country = countries ? countries.find(c => c.name === countryName) : null;
      return country && country.id;
    },
    onSubmit({form, tab}) {
      if (tab === this.TAB_ID_SOURCES) {
        this.$store.commit('mutateSearchAllSourcesFormState', form);
      }
      this.$emit('submit', {tab});
    },
    onValidationError(error) {
      this.$emit('validation-error', error);
    },
    onCancel() {
      this.$emit('cancel');
    },
    goToFullTextTab() {
      this.$refs.tabs.activateTab(TAB_ID_FULL_TEXT);
    },
  },
  components: {
    IndexedRecordsForm,
    FullTextForm,
    TabsBlock,
    TabsBlockItem,
    SourcesForm,
  },
  name: 'FormTabsBlockContainer',
};
</script>

<style scoped lang="scss">
.beta-disclaimer {
  background-color: $neutral-200;
  color: $neutral-500;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
  font-style: italic;

  &::v-deep a {
    color: $neutral-500;
  }
}
</style>
