<template>
  <component :is="resultsComponent" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
  </component>
</template>

<script>
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';

import FullTextResults from './FullTextResults';
import IndexedRecordsResults from './IndexedRecordsResults';
import SourcesResults from './SourcesResults';

export default {
  inheritAttrs: false,
  props: {
    activeTab: String,
  },
  computed: {
    mapping() {
      return {
        [TAB_ID_INDEXED_RECORDS]: IndexedRecordsResults,
        [TAB_ID_FULL_TEXT]: FullTextResults,
        [TAB_ID_SOURCES]: SourcesResults,
      };
    },
    resultsComponent() {
      return this.mapping[this.activeTab];
    },
  },
  components: {FullTextResults, IndexedRecordsResults, SourcesResults},
  name: 'SearchResults',
};
</script>

<style scoped></style>
