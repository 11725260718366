<template>
  <div class="sticky-edit-buttons mobile-filter-buttons" id="mobile-filter-buttons">
    <mcr-button id="mobile-edit-button" class="mobile-edit-button dark-grey" @click="$emit('click-edit-search')">
      <pencil-icon :size="iconSize" class="edit-icon" /><span>{{ editSearchBtnLabel }}</span>
    </mcr-button>

    <div class="edit-filters-wrapper" v-if="showFilters" :class="{applied: appliedFiltersCount}">
      <mcr-button id="mobile-filters-button" class="mobile-edit-button dark-grey small" @click="$emit('click-filters')">
        <filter-icon :size="iconSize" class="edit-icon" /><span>{{ editFiltersBtnLabel }}</span>
      </mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import throttle from 'lodash/throttle';
import FilterIcon from 'vue-material-design-icons/FilterVariant';
import PencilIcon from 'vue-material-design-icons/Pencil';

export default {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    showFilters: Boolean,
    appliedFiltersCount: Number,
  },
  data() {
    return {
      editSearchBtnLabel: 'Edit Search',
      editFiltersBtnLabel: 'Edit Filters',
      pastScroll: false,
      iconSize: 20,
    };
  },
  methods: {
    handleScroll: throttle(function () {
      const newPastScroll = window.scrollY > 180;
      if (newPastScroll !== this.pastScroll) {
        const container = document.getElementById('mobile-filter-buttons');
        const filtersButton = document.getElementById('mobile-filters-button');

        const width = this.showFilters ? 130 : 60;
        container.style.maxWidth = newPastScroll ? `${width}px` : '100%';

        if (newPastScroll) {
          container.classList.add('scrolled');
          filtersButton.classList.remove('small');
          this.iconSize = 30;
        } else {
          container.classList.remove('scrolled');
          filtersButton.classList.add('small');
          this.iconSize = 20;
        }
      }
      this.pastScroll = newPastScroll;
    }, 200),
  },
  components: {PencilIcon, McrButton, FilterIcon},
  name: 'StickyEditButtons',
};
</script>

<style lang="scss" scoped>
.mobile-filter-buttons {
  justify-content: stretch;
  position: sticky;
  position: -webkit-sticky;
  top: 75px;
  display: flex;
  max-width: 100%;
  transition: all 0.2s ease-out;
  left: 95%;
  margin-bottom: 8px;
  z-index: 1;

  .mcr-button {
    transition: none;
  }

  &.scrolled {
    margin-right: -50px;
    margin-bottom: 0;

    .mobile-edit-button {
      width: 48px;
      display: flex;
    }
    .edit-filters-wrapper .mcr-button {
      width: 48px;
    }

    #mobile-edit-button {
      display: flex;
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      margin-right: -20px;
    }
    @media only screen and (max-width: $breakpoint-mobile-se) {
      margin-right: -15px;
    }
  }

  .edit-filters-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    .mcr-button {
      display: flex;
      width: 100%;
    }
  }
}

#mobile-edit-button {
  display: none;
  margin-right: 10px;
}

.mobile-edit-button {
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  justify-content: flex-start;
  min-width: 0;

  &::v-deep .mcr-button-label {
    align-items: center;
    padding: 9px;
  }
}
</style>
