import orderBy from 'lodash/orderBy';

export default {
  selectType(value, typeName, deselectFields) {
    this.$emit('select', {typeName, value, deselectFields});
  },
  deSelectType(typeName) {
    this.$emit('select', {typeName, value: null});
  },
  selectTypeCategory(value) {
    if (value.level === 1) {
      return this.selectType(value.object_id, this.sourceTypeTypeName);
    }
    return this.selectType(value.id, 'category_id', [this.sourceTypeTypeName]);
  },
  deSelectTypeCategory(value) {
    if (value.isHeaderItem) {
      return this.selectType(null, null, [this.sourceTypeTypeName, 'category_id']);
    }
    if (value.level === 1) {
      return this.deSelectType(this.sourceTypeTypeName);
    }
    return this.deSelectType('category_id');
  },
  hasSelectedChild(item) {
    if (!item.children) {
      return false;
    }
    for (let child of item.children) {
      if (child.isSelected || this.hasSelectedChild(child)) {
        return true;
      }
    }
  },
  normalizeOptions(options, typeName, idField = 'object_id', sortByField = 'name', orders = 'asc') {
    let result = [];
    let noneValue = null;
    let selectedValue = null;
    const ordered = orderBy(options, [sortByField], [orders]);
    for (let item of ordered) {
      const isNoneItem = item.object_id === this.noneValue;
      const hasSelectedChild = this.hasSelectedChild(item);

      if (item.isSelected || hasSelectedChild) {
        selectedValue = {...item};
        continue;
      }

      if (this.isTypeItemSelected(item, typeName)) {
        const name = isNoneItem ? this.noneName : item.name;
        selectedValue = {...item, isSelected: !hasSelectedChild, name};
        continue;
      }

      if (isNoneItem) {
        noneValue = item;
        continue;
      }
      result.push(item);
    }

    if (noneValue) {
      result.unshift({...noneValue});
    }
    if (selectedValue) {
      return [selectedValue];
    }
    return result;
  },
};
